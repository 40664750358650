<template>
  <b-table
    :fields="fields"
    :items="settings"
  >
    <template #head(id)="data">
      <b class="table-font">{{ data.label }}</b>
    </template>
    <template class="table-font" v-slot:cell(id)="data">
      <b class="table-font">
        {{ data.item.id }}
      </b>
    </template>
    <template #head(monthly_interest_rate)="data">
      <b class="table-font">{{ data.label }}</b>
    </template>
    <template class="table-font" v-slot:cell(monthly_interest_rate)="data">
      <b class="table-font">
        {{ data.item.monthly_interest_rate.toLocaleString("pt-BR", {
          minimumFractionDigits: 2
        }) }}
      </b>
    </template>
    <template #head(created_at)="data">
      <b class="table-font">{{ data.label }}</b>
    </template>
    <template class="table-font" v-slot:cell(created_at)="data">
      <b class="table-font">
        {{ formatDate(data.item.created_at) }}
      </b>
    </template>
    <template #head(created_by)="data">
      <b class="table-font">{{ data.label }}</b>
    </template>
    <template class="table-font" v-slot:cell(created_by)="data">
      <b class="table-font">
        {{ data.item.created_by }}
      </b>
    </template>
  </b-table>
</template>

<script>
import PlgSettingsApi from "../../apis/PlgSettingsApi.vue";
import moment from "moment";

export default {
  name: "PlgSettingsTable",
  mixins: [
    PlgSettingsApi
  ],
  data() {
    return {
      fields: [
        { key: "id", label: "#" },
        { key: "monthly_interest_rate", label: "Juros Mensais" },
        { key: "created_at", label: "Data" },
        { key: "created_by", label: "Usuário" },
        { key: "actions", label: "" },
      ],
      settings: [],
    }
  },
  methods: {
    async refreshSettings() {
      const response = await this.getSettingsApi(false);
      this.settings = response.data;
    },
    formatDate(date) {
      let newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
  },
  async mounted() {
    await this.refreshSettings();
  }
}
</script>

<style>
.table-font {
  font-size: .9rem;
}
</style>