<template>
  <div
    class="card border rounded"
  >
    <h3
      class="text-muted p-4"
    >
      Log de Configurações
    </h3>
    <div class="p-4 text-center">
      <PlgSettingsTable />
    </div>
  </div>
</template>

<script>
import PlgSettingsTable from "../../components/settings/PlgSettingsTable.vue";

export default {
  name: "PlgSettingsHistory",
  components: {
    PlgSettingsTable,
  }
}
</script>